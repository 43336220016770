<template>
  <header :class="headerClass">
    <div class="tabs">
      <nav class="tab" :class="headerClass" role="navigation">
        <router-link class="tab-link"
          v-for="item in tabItems"
          :key="item.name"
          :to="item.path"
          :aria-current="[ item.name === $route.name ? 'page' : false ]"
          exact>
          <div class="nav-link-content">
            {{ item.name }}
          </div>
          <div class="tab-underline"></div>
        </router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data () {
    return {
      tabItems: [
        {
          name: 'services',
          path: '/services'
        },
        {
          name: 'team',
          path: '/team'
        },
        {
          name: 'location',
          path: '/location'
        }
      ]
    }
  },
  computed: {
    headerClass () {
      if (this.$route.name !== 'home') {
        return 'default'
      }
    }
  }
}
</script>

<style scoped>
header {
  background-color: transparent;
}

header.default {
  background-color: #fff;
}

.tabs {
  position: relative;
  width: 100%;
}

.tab {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0;
  height: 48px;
}

.tab-link {
  width: 33.33%;
  font-weight: 500;
  text-align: center;
}

.tab-link .nav-link-content {
  text-transform: uppercase;
  color: rgba(71,53,80,.7);
}

.tab-link[aria-current="page"] .nav-link-content {
  color: #473550;
}

.tab-underline {
  position: absolute;
  bottom: 0;
  background-color: #cc66ff;
  height: 2px;
}

.tab-link[aria-current="page"] > .tab-underline {
   width: 33.33%;
}

@media (min-width: 37.5em) {
  .tab-link,
  .tab-link[aria-current="page"] > .tab-underline {
    max-width: 10em;
  }
}
</style>
