import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import focusRing from 'wicg-focus-ring'
import vMediaQuery from 'v-media-query'
import VueGtm from 'vue-gtm'
import Meta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(Meta)

Vue.use(vMediaQuery)

Vue.use(VueGtm, {
  vueRouter: router
})

Vue.use(focusRing)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
