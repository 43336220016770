export const members = [
  {
    name: 'Dr. Andria Hoda',
    title: 'Doctor of Chiropractic, Doctor of Acupuncture',
    img: require('@/assets/images/andria-hoda.jpg'),
    school: '<a href="http://www.nycc.edu">New York Chiropractic College</a>, 2005',
    description: `Dr. Andria Hoda has been providing outstanding chiropractic care in the community for over ten years. She is also one of the biggest proponents of continuing education for chiropractors, founding and managing Canada's first online continuing education website for the profession, <a href="http://thedcce.com/">The DCCE</a>. It's Andria's commitment to health and wellness that keeps her clientele coming back and continually referring friends and family to her care.  Dr. Hoda was the recipient of the 2014 Reader's Choice Platinum Award for Best Chiropractor in Hamilton.`
  },
  {
    name: 'Dr. Erica Hoda',
    title: 'Doctor of Chiropractic',
    img: require('@/assets/images/erica-hoda.jpg'),
    school: '<a href="http://www.nycc.edu">New York Chiropractic College</a>, 2006',
    description: `Dr. Erica Hoda graduated from New York Chiropractic College in 2006 after obtaining a degree in Health Sciences from The University of Western Ontario.  She has since been providing chiropractic care to people of all ages with a zestful approach to patient care, as well as her passion for healing. Continuing her education remains a priority as she is committed to providing the very best chiropractic care and health information to her patients, and to the community.  Dr. Hoda has been a Reader's Choice award winner since 2014.`
  }
]
