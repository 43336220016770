<template>
  <footer class="bottom-nav" role="navigation">
    <router-link to="/" class="nav-link" exact>
      <div class="nav-link-content">
        <svg class="icon">
          <use xlink:href="#logo"></use>
        </svg>
        Home
      </div>
    </router-link>
    <a
      class="nav-link"
      :href="'tel:+1' + phone"
    >
      <div class="nav-link-content">
        <svg class="icon">
          <use xlink:href="#phone"></use>
        </svg>
        Call
      </div>
    </a>
    <a
      class="nav-link"
      :href=" 'mailto:' + email "
    >
      <div class="nav-link-content">
        <svg class="icon">
          <use xlink:href="#email"></use>
        </svg>
        Email
      </div>
    </a>
    <a
      class="nav-link"
      href="https://imoveclinic.janeapp.com/"
      :aria-current="[ $route.name === 'book' ? 'page' : false ]"
      target="_blank"
      rel="noopener"
    >
      <div class="nav-link-content">
        <svg class="icon">
          <use xlink:href="#book"></use>
        </svg>
        Book
      </div>
    </a>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      phone: '905-304-8100',
      email: 'meadowlands@live.com'
    }
  }
}
</script>

<style scoped>
.icon {
  margin: 7px auto 0;
}

.nav-link {
  flex: 0 1 auto;
  margin: 0 1em;
  font-size: .7rem;
  max-width: 4.25em;
}

.nav-link .nav-link-content {
  flex-direction: column;
}

.icon {
  fill: #cc66ff;
  width: 24px;
  height: 24px;
}

.bottom-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 3em;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 3px 14px 2px rgba(0,0,0,.12);
}

.bottom-nav .nav-link {
  display: inline-flex;
  margin: 0 1em;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.bottom-nav .nav-link:focus,
.bottom-nav .nav-link[aria-current="page"] {
  color: #cc66ff;
  background-color: #f2f2f6;
}

.bottom-nav .nav-link:hover .nav-link-content {
  color: #b31aff;
}

.bottom-nav .nav-link:hover .nav-link-content .icon {
  fill: #b31aff;
}

.bottom-nav .nav-link .nav-link-content {
  display: flex;
  height: 100%;
  color: #cc66ff;
}
</style>
