<template>
  <footer class="bottom-nav" role="navigation">
    <router-link to="/" class="nav-link" exact>
      <div class="nav-link-content">
        <svg class="icon">
          <use xlink:href="#logo"></use>
        </svg>
        Home
      </div>
    </router-link>
    <router-link
      to="/contact"
      class="nav-link"
      :aria-current="[ $route.name === 'contact' ? 'page' : false ]"
      >
      <div class="nav-link-content">
        <svg class="icon">
          <use xlink:href="#contact"></use>
        </svg>
        Contact us
      </div>
    </router-link>
    <a
      class="nav-link"
      href="https://imoveclinic.janeapp.com/"
      :aria-current="[ $route.name === 'book' ? 'page' : false ]"
      target="_blank"
      rel="noopener"
    >
      <div class="nav-link-content">
        <svg class="icon">
          <use xlink:href="#book"></use>
        </svg>
        Book online
      </div>
    </a>
  </footer>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style scoped>
.icon {
  margin: 0 7px 0 0;
}

.nav-link {
  flex-direction: column;
  margin: 0 1rem;
  font-size: .9rem;
  max-width: 10rem;
}

.nav-link-content {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon {
  fill: #cc66ff;
  width: 24px;
  height: 24px;
}

.bottom-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 3em;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 3px 14px 2px rgba(0,0,0,.12);
}

.bottom-nav .nav-link {
  display: inline-flex;
  margin: 0 1em;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.bottom-nav .nav-link:focus,
.bottom-nav .nav-link[aria-current="page"] {
  color: #cc66ff;
  background-color: #f2f2f6;
}

.bottom-nav .nav-link:hover .nav-link-content {
  color: #b31aff;
}

.bottom-nav .nav-link:hover .nav-link-content .icon {
  fill: #b31aff;
}

.bottom-nav .nav-link .nav-link-content {
  display: flex;
  height: 100%;
  color: #cc66ff;
}
</style>
