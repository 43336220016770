export const services = [
  {
    title: 'Chiropractic Treatment',
    preview: `<h2>What is chiropractic treatment?</h2>
    <p>A chiropractic adjustment is a procedure in which your chiropractor applies a controlled force to an area of your body, usually a spinal joint.  This manipulation can be done with the hands or a specialized instrument while you are sitting or lying down.</p>`,
    more: `<p>At Meadowlands Chiropractic, we use a chiropractic technique known as Torque Release to gently and efficiently correct our patients' structural alignment and improve the body's physical function.</p>
    <h2>Who is chiropractic treatment for?</h2>
    <p>Patients often seek chiropractic treatment for issues such as headaches, neck, or back pain, but a chiropractic evaluation and adjustment is suitable for anyone interested in an overall holistic approach to their health.</p>

    <h2>Is chiropractic treatment safe?</h2>
    <p>The Doctors at Meadowlands Chiropractic, Drs. Andria and Erica Hoda, are licenced Doctors of Chiropractic (D.C.) with over a decade experience each.</p>
    <p>Chiropractic treatment using the Torque Release technique is safe for patients of all ages, including infants and children.</p>

    <h2>What can I expect when I come for a treatment?</h2>
    <p>No special preparation is required before a chiropractic adjustment, but you should wear loose, comfortable clothing.</p>
    <p>During your first visit, your chiropractor will ask you questions about your health and perform a non-invasive physical examination to assess your needs and recommend a course of treatment suited to your individual circumstances.</p>
    <p>A typical chiropractic adjustment often involves lying in a facedown position on a specially designed, padded chiropractic table.  Your chiropractor will place you in specific positions to treat affected areas.</p>
    
    <h2>Is chiropractic treatment covered by insurance?</h2>
    <p>Many health insurance policies cover chiropractic care, but you might want to check to see how many treatments are covered in a given time period.</p>`
  },
  {
    title: 'Acupuncture',
    preview: `<h2>What is acupuncture?</h2>
    <p>Based on traditional Chinese medicine, acupuncture is a technique for balancing the energy believed to flow through pathways (meridians) in your body. By inserting needles into specific points relative to nerves, muscles and connective tissue, this stimulation boosts your body's natural painkillers and increases blood circulation.</p>`,
    more: `<p>Acupuncture is commonly used to treat pain by means of insertion of extremely thin needles through your skin at strategic points on your body.</p>

    <h2>Who is acupuncture for?</h2>
    <p>Acupuncture is used mainly to relieve discomfort associated with a variety of diseases and conditions.</p>

    <h2>What can I expect when I come for a treatment?</h2>
    <p>No special preparation is required before acupuncture, but you should wear loose, comfortable clothing.</p>
    <p>Your acupuncturist, Dr. Andria Hoda, will blend aspects of Eastern and Western approaches to medicine. To determine the type of acupuncture treatment that will help you the most, the doctor may ask you about your symptoms, behaviors, and lifestyle.</p>
    <p>In most cases, the needles remain in place for 10 to 20 minutes while you lie still and relax.  Acupuncture needles are very thin, so insertion usually causes little discomfort.  There is usually no discomfort when the needles are removed.  After acupuncture treatment, you may feel relaxed while others may feel energized.</p>`
  }
]
