<template>
  <div>
    <form
      name="contact"
      method="post"
      netlify
      netlify-honeypot="bot-field"
      @submit="handleSubmit"
    >
      <input type="hidden" name="form-name" value="netlify-form" />
      <div hidden>
        <label for="bot-field">
          Don’t fill this out: <input name="bot-field" />
        </label>
      </div>
      <div class="field">
        <label for="name">Name:
          <input
            type="text"
            name="name"
            v-model="formData.name"
            required
          />
        </label>
      </div>
      <div class="field">
        <label for="email">Email:
          <input
            type="email"
            name="email"
            v-model="formData.email"
            required
          />
        </label>
      </div>
      <div class="field">
        <label for="message">Message:
          <textarea
            name="message"
            v-model="formData.message"
            required
          />
        </label>
      </div>
      <div>
        <label class="subtext" for="disclaimer">
          <input name="disclaimer" type="checkbox" v-model="checked" />
          I certify that this message does not contain any <a href="https://www.hhs.gov/hipaa/for-individuals">Protected Health Information</a>
        </label>
      </div>
      <br/>
      <app-flat-button
        :label="'Send'"
        :type="'submit'"
        :disabled="!checked"
      />
    </form>
    <transition name="show-more">
      <app-alert
        v-if="formAlert.show"
        :text="formAlert.msg"
        :alertClass="formAlert.class"
        @dismissed="dismissAlert"
      />
    </transition>
  </div>
</template>

<script>
import appFlatButton from './FlatButton'
import appAlert from './Alert'
function encode (data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
export default {
  data () {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      checked: false,
      formAlert: {
        show: false,
        msg: '',
        class: ''
      }
    }
  },
  components: {
    appFlatButton,
    appAlert
  },
  methods: {
    handleSubmit (e) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...this.formData })
      })
        .then(res => {
          if (res.status === 200) {
            this.formData = {
              name: '',
              email: '',
              message: ''
            }
            this.formAlert = {
              show: true,
              msg: 'Thank you for your message.  We will reply to you shortly.',
              class: 'success'
            }
          } else {
            this.formAlert = {
              show: true,
              msg: `There was an error: ${res.status} ${res.statusText}. Try again or email us directly.`,
              class: 'error'
            }
          }
          this.checked = false
        })
        .catch(error => {
          this.formAlert = {
            show: true,
            msg: `${error.message}. Try again or email us directly.`,
            class: 'error'
          }
        })

      e.preventDefault()
    },
    dismissAlert () {
      this.formAlert.show = false
    }
  }
}
</script>

<style scoped>
form {
  text-align: left;
  padding: 1rem;
}

.form > *:not(.card-actions) {
  padding-left: 1rem;
  padding-right: 1rem;
}

input:not([type=checkbox]):not([type=radio]),
select,
textarea {
  display: block;
  width: 100%;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  padding: .375rem .625rem;
  border: 2px solid rgba(71,53,80,.12);
  border-radius: .125rem;
  -webkit-appearance: none;
}

input:not([type=checkbox]):not([type=radio]):focus,
select:focus,
textarea:focus {
  border-color: #cc66ff;
  outline: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

label {
  margin-bottom: .5rem;
}

.field {
  margin-bottom: 1rem;
}

.field.error > input,
.field.error > select,
.field.error > textarea {
  border-color: #e20068;
}
.field.error > input > .hint,
.field.error > select > .hint,
.field.error > textarea > .hint {
  color: #e20068;
}

.field.success > input,
.field.success > select,
.field.success > textarea {
  border-color: #00cc66;
}
.field.success > input > .hint,
.field.success > select > .hint,
.field.success > textarea > .hint {
  color: #00cc66;
}

.hint {
  margin: .25rem 0 0;
  font-size: 80%;
  color: rgba(71,53,80,.54);
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: .5rem 0;
  border: 0;
}

textarea {
  width: 100%;
}
</style>
