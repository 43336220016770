export const hours = [
  {
    day: 'Monday',
    hours: 'Closed'
  },
  {
    day: 'Tuesday',
    hours: '9 AM - 12 PM & 3 PM - 7 PM'
  },
  {
    day: 'Wednesday',
    hours: 'Closed'
  },
  {
    day: 'Thursday',
    hours: '9 AM - 12 PM & 3 PM - 7 PM'
  },
  {
    day: 'Friday',
    hours: 'Closed'
  },
  {
    day: 'Saturday',
    hours: '9 AM - 1 PM'
  },
  {
    day: 'Sunday',
    hours: 'Closed'
  }
]
