<template>
  <section class="container">
    <transition name="scale-down" appear>
      <div class="title">
        <div>
          <svg class="icon">
            <use xlink:href="#logo"></use>
          </svg>
        </div>
        <div>
          <h1>
            Meadowlands<br>
            <span class="smaller">Chiropractic</span>
          </h1>
        </div>
      </div>
    </transition>
    <transition name="slight-down" appear>
        <div class="address">
        <h2>We are moving July 27</h2>
        <p>Visit us at our new location:</p>
        <span>iMove Clinic</span>
        <address>
          {{ address }}<br>
          {{ locality }}
          <span v-if="$mq.resize && $mq.above('600px')"><br>{{ buttons[0].label }}</span>
        </address>
      </div>
    </transition>
    <div class="cta-container">
      <div class="button-zone">
        <transition-group name="slide-up" appear>
          <div
            class="button-house"
            v-if="$mq.resize && $mq.below('600px')"
            key="1"
          >
            <a
              role="button"
              class="btn primary"
              :href="this.buttons[0].href"
            >
              <div class="button-content">
                <svg class="icon">
                  <use :xlink:href="this.buttons[0].icon"></use>
                </svg>
                &nbsp;
                {{ this.buttons[0].label }}
              </div>
            </a>
          </div>
          <div
            class="button-house"
            v-if="$mq.resize && $mq.below('600px')"
            key="2"
          >
            <a
              role="button"
              class="btn primary"
              :href="this.buttons[1].href"
            >
              <div class="button-content">
                <svg class="icon">
                  <use :xlink:href="this.buttons[1].icon"></use>
                </svg>
                &nbsp;
                {{ this.buttons[1].label }}
              </div>
            </a>
          </div>
          <div
            class="button-house"
            v-if="$mq.resize && $mq.above('600px')"
            key="3"
          >
            <router-link
              role="button"
              class="btn primary"
              :to="this.buttons[2].href"
            >
              <div class="button-content">
                <svg class="icon">
                  <use :xlink:href="this.buttons[2].icon"></use>
                </svg>
                &nbsp;
                {{ this.buttons[2].label }}
              </div>
            </router-link>
          </div>
          <div
            class="button-house"
            key="4"
          >
            <a
              role="button"
              class="btn primary"
              :href="this.buttons[3].href"
              target="_blank"
              rel="noopener"
            >
              <div class="button-content">
                <svg class="icon">
                  <use :xlink:href="this.buttons[3].icon"></use>
                </svg>
                &nbsp;
                {{ this.buttons[3].label }}
              </div>
            </a>
          </div>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: 'Meadowlands Chiropractic | Hamilton Chiropractors',
    titleTemplate: null
  },
  data () {
    return {
      attribute: 'v-if="something"',
      something: true,
      address: '30 Rymal Rd. East',
      locality: 'Hamilton, ON',
      buttons: [
        {
          label: '905-304-8100',
          icon: '#phone',
          href: 'tel:+19053048100'
        },
        {
          label: 'Email us',
          icon: '#email',
          href: 'mailto:meadowlands@live.com'
        },
        {
          label: 'Contact us',
          icon: '#contact',
          href: '/contact'
        },
        {
          label: 'Book online',
          icon: '#book',
          href: 'https://imoveclinic.janeapp.com/'
        }
      ]
    }
  }
}
</script>

<style scoped>
.title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.title .icon {
  fill: #473550;
  width: 5rem;
  height: 5rem;
}

@media (min-width: 37.5rem) {
  .title .icon {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.title h1 {
  font-weight: 600;
  font-size: 1.5rem;
  color: #473550;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media (min-width: 37.5rem) {
  .title h1 {
    font-size: 2.2rem;
  }
}

.title .smaller {
  font-size: 1.2rem;
}

@media (min-width: 37.5rem) {
  .title .smaller {
    font-size: 1.5rem;
  }
}

.address {
  padding: 1rem 0;
  text-align: center;
}

.address address {
  font-weight: 500;
  font-size: 1rem;
  font-style: normal;
  line-height: 2rem;
  letter-spacing: .2em;
  text-transform: uppercase;
}

.address h2 {
  color: #cc66ff;
}

.cta-container {
  padding: 2rem 0 0 0;
}

.button-zone {
  margin-bottom: 1rem;
}

@media (min-width: 37.5rem) {
  .button-zone {
    margin-bottom: 1.5rem;
  }
}

.button-house {
  text-align: center;
  margin-bottom: 1rem;
}

.btn {
  display: inline-block;
  height: 2.625rem;
  padding: 0 1.2rem;
  font-size: .8125rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  transition: box-shadow .3s ease-out;
}

.btn.primary {
  background-color: #cc66ff;
  color: #fff;
  border-radius: 1.3125rem;
  min-width: 12.5rem;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  letter-spacing: .2em;
}

.btn.primary:hover,
.btn.primary:active,
.btn.primary:focus {
  box-shadow: 0 5px 15px rgba(0,0,0,.3);
}

.button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.button-content .icon {
  fill: #fff;
  width: 24px;
  height: 24px;
}
</style>
