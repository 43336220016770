const mapMarker = {
  position: { lat: 43.2417613, lng: -79.8565034 },
  optimized: false,
  icon: {
    url: require('@/assets/icons/map-marker.svg')
  }
}
const text = '#473550'
const primary = '#cc66ff'
const bgLight = '#d4d3e2'
const bgDark = '#babfd4'
const grey = '#eee'
const white = '#ffffff'

const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [{ color: grey }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: bgDark }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: white }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: primary }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: white }]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: bgLight }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: text }]
  }
]

export { mapMarker, mapStyles }
