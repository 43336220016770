<template>
  <section class="container">
    <transition-group name="slide-up" appear>
      <appCard key="1">
        <div class="address">
          <div class="card-content">
            <div class="card-header">
              <h1 class="primary-text">Visit our clinic</h1>
              <address class="subtext">
                30 Rymal Rd. East<br>
                Hamilton, ON<br>
                L9B 1B9
              </address>
            </div>
          </div>
        </div>
      </appCard>
      <appCard key="2">
        <div class="hours">
          <div class="card-header">
            <h2 class="primary-text">Flexible hours to fit your schedule</h2>
            <table class="subtext" cellspacing="0">
              <tbody>
                <tr
                  v-for="day in hours"
                  :key="day.day"
                  :aria-current="[ day.day === currentDay ? 'date' : false ]"
                  itemprop="openingHours"
                >
                  <td>{{ day.day }}:</td>
                  <td>{{ day.hours }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </appCard>
      <appCard key="3">
        <app-google-map />
      </appCard>
    </transition-group>
  </section>
</template>

<script>
import { hours } from '@/assets/data/hours'
import appCard from '@/components/Card'
import appGoogleMap from '@/components/GoogleMap'
export default {
  metaInfo: {
    title: 'Hamilton Location',
    meta: [
      { vmid: 'description', name: 'description', content: 'We are located at 30 Rymal Rd. E in Hamilton and have flexible office hours to fit your schedule.' }
    ]
  },
  data () {
    return {
      hours,
      currentDate: new Date(),
      weekday: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    }
  },
  components: {
    appCard,
    appGoogleMap
  },
  computed: {
    currentDay () {
      return this.weekday[this.currentDate.getDay()]
    }
  }
}
</script>

<style scoped>
.card-content {
  width: 100%;
  text-align: center;
}

.hours td {
  padding: 2px 5px;
}

.hours td + td {
  text-align: right;
}

.hours .subtext {
  width: 100%;
}

.hours [aria-current="date"] {
  color: #473550;
  background-color: rgba(204,102,255,.3);
}
</style>
