<template>
  <div class="google-map" :id="mapName"></div>
</template>

<script>
import { mapStyles } from '@/assets/data/map'
export default {
  name: 'google-map',
  props: ['name'],
  data () {
    return {
      mapName: this.name + '-map',
      markerCoordinates: [{
        latitude: 43.2015671,
        longitude: -79.8914065
      }]
    }
  },
  /* eslint-disable */
  methods: {
    initMap () {
      const element = document.getElementById(this.mapName)
      const options = {
        zoom: 14,
        center: new google.maps.LatLng(43.2015671,-79.8914065),
        styles: mapStyles
      }
      const map = new google.maps.Map(element, options)

      this.markerCoordinates.forEach((coord) => {
        const position = new google.maps.LatLng(coord.latitude, coord.longitude);
        const marker = new google.maps.Marker({ 
          position,
          map,
          icon: {
            url: require('@/assets/icons/map-marker.svg'),
            scaledSize: new google.maps.Size(19, 34)
          },
          optimized: false
        })
      })
    }
  },
  mounted () {
    this.initMap()
  }
};
</script>

<style scoped>
.google-map {
  height: 500px;
}
</style>
