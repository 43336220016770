<template>
  <div
    class="alert"
    :class="alertClass"
  >
    <button
      class="dismiss"
      aria-label="Dismiss"
      @click="onDismiss"
    ></button>
    {{ text }}
  </div>
</template>

<script>
export default {
  props: ['text', 'alertClass'], // Alert class can be 'error' or 'success'
  methods: {
    onDismiss () {
      this.$emit('dismissed')
    }
  }
}
</script>

<style scoped>
.alert {
  position: relative;
  margin: 0 1rem 1rem 1rem;
  padding: 1rem;
  border-radius: .125em;
  color: #fff;
}

.dismiss {
  position: absolute;
  right: -.4rem;
  top: -.4rem;
  display: inline-block;
  padding: 0;
  line-height: 1.25em;
  height: 1.25em;
  width: 1.25em;
  min-height: 1.25em;
  min-width: 1.25em;
  max-height: 1.25em;
  max-width: 1.25em;
  user-select: none;
  border: none;
  border-radius: 50%;
  background: #473550;
  font-size: 1rem;
  overflow: hidden;
}

.alert .btn {
  margin: 0;
}

.alert.error {
  background-color: #e20068;
}

.alert.success {
  background-color: #00cc66;
}

.dismiss::before, .dismiss::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  background-color: #fff;
  transform-origin: center center;
  height: 2px;
  width: 50%;
}

.dismiss::before {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.dismiss::after {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
</style>
