<template>
  <section class="container">
    <transition-group name="slide-up" appear>
      <appCard key="1">
        <div class="card-header">
          <h1 class="primary-text">Get in touch</h1>
        </div>
        <div class="card-content">
          <div class="grid">
            <div>
              <p class="subtext">
                Call:
                <a
                  :href="'tel:' + this.phone"
                >
                  {{phone}}
                </a>
              </p>
            </div>
            <div>
              <p class="subtext">
                Email:
                <a
                  :href="'mailto:' + this.email"
                >
                  {{email}}
                </a>
              </p>
            </div>
            <div>
              <p class="subtext">
                <router-link to="/location">Map and address</router-link>
              </p>
            </div>
          </div>
        </div>
      </appCard>
      <appCard key="2">
        <div class="card-header">
          <h1 class="primary-text">Send us a message</h1>
        </div>
        <app-contact-form />
      </appCard>
    </transition-group>
  </section>
</template>

<script>
import appCard from '@/components/Card'
import appContactForm from '@/components/ContactForm'
export default {
  metaInfo: {
    title: 'Contact us for an appointment',
    meta: [
      { vmid: 'description', name: 'description', content: 'Send us a message, an email, or call us at 905-304-8100 to schedule an appointment or ask us about our services.' }
    ]
  },
  data () {
    return {
      phone: '905-304-8100',
      email: 'meadowlands@live.com'
    }
  },
  components: {
    appContactForm,
    appCard
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  padding-bottom: 1rem;
}

@media (min-width: 37.5rem) {
  .grid {
    grid-template-columns: repeat(3, auto);
  }
}
</style>
