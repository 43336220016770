<template>
  <section class="container">
    <appCard>
      <div class="card-header">
        <h1 class="primary-text">Page Not found</h1>
      </div>
      <div class="card-content">
        <p class="subtext">
          Looks like you've followed a broken link or entered a URL that doesn't exist on this site.
        </p>
        <br>
        <div class="hr"></div>
        <p class="subtext">
          <router-link to="/">Home page</router-link>
        </p>
      </div>
    </appCard>
  </section>
</template>

<script>
import appCard from '@/components/Card'
export default {
  metaInfo: {
    title: 'Page not found',
    meta: [
      { vmid: 'description', name: 'description', content: 'Looks like you\'ve followed a broken link or entered a URL that doesn\'t exist on this site.' }
    ]
  },
  data () {
    return {
      phone: '905-304-8100',
      email: 'meadowlands@live.com'
    }
  },
  components: {
    appCard
  }
}
</script>
