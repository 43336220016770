<template>
  <footer class="footer">
    &copy; {{ year }} {{ company }}
  </footer>
</template>

<script>
export default {
  data () {
    return {
      company: 'Meadowlands Chiropractic',
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.footer {
  font-size: .8rem;
  color: rgba(71,53,80,.7);
  text-align: center;
}

@media (min-height: 50rem) {
  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
}
</style>
