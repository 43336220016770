<template>
  <button
    class="btn flat"
    role="button"
    :aria-pressed="toggled || null"
    @click="$emit('action')"
    :type="type || 'button'"
    :disabled="disabled"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: ['label', 'toggled', 'type', 'disabled']
}
</script>

<style scoped>
button {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button > * {
  pointer-events: none;
}

.btn,
a.btn {
  display: inline-block;
  min-width: 4rem;
  height: 2.25rem;
  padding: 0 1rem;
  font-size: .8125rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  border-radius: .125rem;
}

.btn.flat,
a.btn.flat {
  background-color: transparent;
  color: #cc66ff;
  padding: 0 .5rem;
  min-width: 5.5rem;
  margin-left: 0;
  transition: background-color .3s ease-out;
}

.btn.flat:hover,
a.btn.flat:hover {
  background-color: rgba(204,102,255,.12);
}

.btn.flat[disabled],
a.btn.flat[disabled],
.btn.flat[disabled]:hover,
a.btn.flat[disabled]:hover {
  cursor: not-allowed;
  background-color: #f2f2f6;
  color: #d4d3e2;
}
</style>
