<template>
  <section class="container">
    <transition-group name="slide-up" appear>
      <appCard
        v-for="member in members"
        :key="member.name"
      >
        <div class="card-preview">
          <div class="card-avatar">
            <img :src="member.img" :alt="member.name" :title="member.name"/>
          </div>
          <div class="card-header">
            <h2 class="primary-text">{{ member.name }}</h2>
            <p class="secondary-text">{{ member.title }}</p>
            <p
              class="subtext"
              v-if="$mq.resize && $mq.above('768px')"
              v-html="member.school"
            >
            </p>
          </div>
        </div>
        <transition
          name="show-more"
          mode="out-in"
        >
          <div
            class="card-content"
            v-show="showMore === member.name"
            style="will-change: opacity"
          >
            <div class="hr"></div>
            <p
              class="subtext"
              v-if="$mq.resize && $mq.below('768px')"
              v-html="member.school"
            >
            </p>
            <div>
              <p v-html="member.description"></p>
            </div>
          </div>
        </transition>
        <div class="card-actions">
          <app-flat-button
            @action="showMore = showMore !== member.name ? member.name : null"
            :toggled="showMore === member.name ? 'true' : 'false'"
            :label="showMore === member.name ? 'Close' : 'Read more'"
          />
        </div>
      </appCard>
    </transition-group>
  </section>
</template>

<script>
import { members } from '@/assets/data/members'
import appCard from '@/components/Card'
import appFlatButton from '@/components/FlatButton'
export default {
  metaInfo: {
    title: 'Doctors of Chiropractic',
    meta: [
      { vmid: 'description', name: 'description', content: 'Dr. Andria Hoda and Dr. Erica Hoda each have over a decade of experience providing chiropractic care in Hamilton, Ontario.' }
    ]
  },
  data () {
    return {
      showMore: null,
      members
    }
  },
  components: {
    appCard,
    appFlatButton
  }
}
</script>

<style scoped>
.card-preview {
  display: flex;
  width: 100%;
}

.card-avatar {
  height: 100px;
  width: 100px;
  margin: 1rem;
  flex-shrink: 0;
}
.card-avatar img {
  width: 100%;
}

.subtext {
  line-height: 1;
}
</style>
