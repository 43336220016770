<template>
  <div id="app">
    <app-bg-left  v-if="$mq.resize && $mq.above('768px')"/>
    <app-bg-right  v-if="$mq.resize && $mq.above('768px')"/>
    <app-bg-bottom v-if="$mq.resize && $mq.below('768px')"/>
    <app-header/>
    <main :class="$route.name">
      <router-view />
    </main>
    <app-footer v-if="$route.name === 'home'" />
    <app-bottom-nav v-else />
    <sprites />
  </div>
</template>

<script>
import sprites from '@/assets/icons/Sprites'
import appHeader from '@/components/Header'
import appFooter from '@/components/Footer'
import appBottomNav from '@/components/BottomNav'
import appBgLeft from '@/components/BgLeft'
import appBgRight from '@/components/BgRight'
import appBgBottom from '@/components/BgBottom'
export default {
  metaInfo: {
    title: 'Hamilton\'s Chiropractors',
    titleTemplate: '%s | Meadowlands Chiropractic'
  },
  components: {
    sprites,
    appHeader,
    appFooter,
    appBottomNav,
    appBgLeft,
    appBgRight,
    appBgBottom
  }
}
</script>

<style>
@import url("https://use.typekit.net/lzk0fss.css");

[v-cloak] {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'futura-pt',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif;
  font-weight: 500;
  font-size:  16px;
  line-height: 1.6;
  color: #473550;
  margin: 0;
  padding: 0;
  background: #d4d3e2;
  overflow-y: scroll;
}

main {
  padding-bottom: 3em;
}

a {
  text-decoration: none;
  transition: color .3s ease-out
}

a,
a:link,
a:visited,
a:active {
  color: #cc66ff;
  background-color: transparent;
}

a:hover {
  color: #b31aff;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: .5em;
}

@media (min-width: 37.5em) {
  .container {
    width: 35em;
    padding: 1rem 0;
  }
}

.primary-text {
  margin: 0 0 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.secondary-text {
  margin: 0 0 .5em;
  font-size: 1rem;
  color: rgba(71,53,80,.7);
}

.subtext {
  color: rgba(71,53,80,.7);
  margin: 0;
  font-style: normal;
}

.hr {
  background-color: rgba(71,53,80,.12);
  height: 2px;
  margin-bottom: 1rem;
}

.js-focus-ring :focus:not(.focus-ring) {
  outline-width: 0;
}

.show-more-enter,
.show-more-leave-to {
  opacity: 0;
}

.show-more-enter-active,
.show-more-leave-active {
  transition: opacity .5s ease-out;
}

.scale-down-enter,
.scale-down-leave-to {
  opacity: 0;
  transform: scale(1.1);
}

.scale-down-enter-active,
.scale-down-leave-active {
  transition: opacity .7s, transform .7s ease-out;
}

.slide-up-enter,
.slide-up-leave-to {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: opacity .7s, transform .7s ease-out;
}

.slight-down-enter,
.slight-down-leave-to {
  opacity: 0;
  transform: translate3d(0, -15px, 0);
}

.slight-down-enter-active,
.slight-down-leave-active {
  transition: opacity .7s, transform .7s ease-out;
}
</style>
