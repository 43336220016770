import Vue from 'vue'
import Router from 'vue-router'
import Team from '@/views/team'
import LocationPage from '@/views/location'
import Services from '@/views/services'
import Home from '@/views/home'
import Contact from '@/views/contact'
import p404 from '@/views/404'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { gtm: 'Homepage' }
    },
    {
      path: '/team',
      name: 'team',
      component: Team,
      meta: { gtm: 'Team' }
    },
    {
      path: '/location',
      name: 'location',
      component: LocationPage,
      meta: { gtm: 'Location' }
    },
    {
      path: '/services',
      name: 'services',
      component: Services,
      meta: { gtm: 'Services' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: { gtm: 'Contact' }
    },
    {
      path: '*',
      component: p404
    }
  ]
})
