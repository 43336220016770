<template>
  <section class="container">
    <transition-group name="slide-up" appear>
      <appCard
        v-for="item in services"
        :key="item.title"
      >
        <div class="card-header">
          <h1 class="primary-text">{{ item.title }}</h1>
        </div>
        <div class="card-content">
          <div class="hr"></div>
          <div v-html="item.preview"></div>
          <transition
            name="show-more"
            mode="out-in"
          >
            <div
              v-show="showMore === item.title"
              v-html="item.more"
              style="will-change: opacity"
            ></div>
          </transition>
        </div>
        <div class="card-actions">
          <app-flat-button
            @action="showMore = showMore !== item.title ? item.title : null"
            :label="showMore === item.title ? 'Close' : 'Read more'"
          >
          </app-flat-button>
        </div>
      </appCard>
      <appCard key="3">
        <div class="card-content">
          <p>Please don't hesitate to <router-link to="/contact">contact us</router-link> if you have any questions.  Your health and well-being are of primary concern to us; we aim to provide a level of care at our clinic that exceeds your expectations.</p>
        </div>
      </appCard>
    </transition-group>
  </section>
</template>

<script>
import { services } from '@/assets/data/services'
import appCard from '@/components/Card'
import appFlatButton from '@/components/FlatButton'
export default {
  metaInfo: {
    title: 'Chiropractic and Acupuncture Services',
    meta: [
      { vmid: 'description', name: 'description', content: 'What can I expect when I come for a chiropractic or acupuncture treatment? During your first visit, your doctor will ask you questions about your health.' }
    ]
  },
  data () {
    return {
      showMore: null,
      services
    }
  },
  components: {
    appCard,
    appFlatButton
  }
}
</script>
