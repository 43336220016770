<template>
  <div>
    <app-bottom-nav-desktop v-if="$mq.resize && $mq.above('600px')"/>
    <app-bottom-nav-mobile v-else/>
  </div>
</template>

<script>
import appBottomNavMobile from '@/components/BottomNavMobile'
import appBottomNavDesktop from '@/components/BottomNavDesktop'
export default {
  components: {
    appBottomNavMobile,
    appBottomNavDesktop
  }
}
</script>
